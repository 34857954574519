<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="content">
    <div class="content-left">
      <a-input
        v-model:value="searchValue"
        @change="handledSearchValue"
        allowClear
        :maxlength="30"
        placeholder="请输入组织名称查询"
        :disabled="disabled"
      >
        <template #prefix>
          <img src="@/assets/svg/search.svg" alt="" />
        </template>
      </a-input>
      <div class="tree-view">
        <div class="tree-view-item show-scrollbar">
          <tree
            :data="treeData"
            @search="onSearch"
            :searchValue="searchValue"
            @onSelect="onSelect"
            @edit="handledEdit"
            :currentDepartId="departmentId"
            :isDisabled="disabled"
            :isShowOperation="isShowOperation"
          />
        </div>
      </div>
      <div class="addOrgBtn" ref="leftBtnRef">
        <!-- <a-button type="dashed" @click="openModal('add', 'treeAdd')" v-btn="'PC_DEPT_ADD'" :disabled="isSort">
          <template #icon><plus-outlined /></template>
          添加组织
        </a-button> -->
      </div>
    </div>
    <div class="content-right">
      <div>{{ departmentTitle }}</div>
      <div class="content-btn">
        <div class="search-view">
          <a-input
            class="form-input"
            allowClear
            v-model:value="searchVal"
            :maxlength="20"
            placeholder="请输入人员名称或账号查询"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" alt="" />
            </template>
          </a-input>
          <a-button type="primary" @click="handledSearch" class="form-btn">查询</a-button>
        </div>
        <div class="search-view">
          <!-- 添加人员 -->
          <!-- <a-button
            type="primary"
            class="add-btn form-btn"
            @click="onClickAddStaff"
            v-btn="'PC_USER_ADD'"
            v-if="isShowAddBtn"
            :disabled="isSort"
          >
            <PlusOutlined class="add-icon" />添加人员
          </a-button> -->
          <!-- 人员排序-集团和子公司下可以进行排序 -->
          <a-button @click="getSort" class="form-btn" :disabled="isSort" v-btn="'PC_USER_UPDATE'">调整排序</a-button>
          <!-- 
            v-if="departmentId === 0 || enterpriseRel !== null"
           -->
          <!-- <a-button @click="openModal('import', 'import')" class="form-btn" v-btn="'PC_USER_UPDATE'" :disabled="isSort"
            >批量导入</a-button
          > -->
        </div>
      </div>

      <a-table
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="tableLoading"
        :rowKey="record => record.staffId"
        ref="tableRef"
        :scroll="{
          x: 400,
          y: isChecked || isSort || (enterpriseRel === null && departmentId !== 0 && !isChecked) ? 440 : 545
        }"
        :row-selection="
          isSort || !isShowAddBtn
            ? null
            : { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelectAll: onSelectAll }
        "
        :customRow="isSort ? customRow : null"
        v-loadMore.expand="{ func: tableLoad, target: '.ant-table-body', delay: 300, distance: 10 }"
      >
        <!-- 排序 -->
        <template #sort>
          <!-- <MenuOutlined /> -->
          <HolderOutlined />
        </template>
        <!-- 序号 -->
        <template #id1="{ index }">
          <span>{{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}</span>
        </template>

        <template #staffId="{text}">
          <span>{{ text }}</span>
        </template>
        <!-- 姓名 -->
        <template #staffName="{ text, record }">
          <div class="name-container">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 6">
                <template #title>
                  <span>{{ text ?? '-' }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
            <img v-if="record.identity === 1" src="@/assets/images/admin.png" alt="" />
          </div>
        </template>
        <!-- 账号 -->
        <template #account="{ text }">
          <div class="name-container">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 6">
                <template #title>
                  <span>{{ text ?? '-' }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </div>
        </template>
        <!-- 手机号 -->
        <template #mobile="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 所属组织 -->
        <template #departmentName="{ text }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length > 6">
              <template #title>
                <span>{{ text ?? '-' }}</span>
              </template>
              {{ text ?? '-' }}
            </a-tooltip>
            <span v-else>{{ text ?? '-' }}</span>
          </div>
        </template>
        <!-- 授权角色 -->
        <template #roleVOList="{ text }">
          <span v-if="!text?.length">-</span>
          <div v-else class="text-ellipsis-10">
            <a-tooltip>
              <template #title>
                <template v-for="(it, idx) in text" :key="it.roleId">
                  {{ it.roleName }}
                  <span v-if="idx != text.length - 1">、</span>
                </template>
              </template>
              <template v-for="(it, idx) in text" :key="it.roleId">
                {{ it.roleName }}
                <span v-if="idx != text.length - 1">、</span>
              </template>
            </a-tooltip>
          </div>
        </template>
        <!-- 指纹录入 -->
        <template #fingerprintEntry="{ text }">
          <span>{{ text ? '已录入' : '未录入' }}</span>
        </template>
        <!-- 操作 -->
        <!-- <template #action="{ record }">
          <div class="tab-btn">
            <a
              @click="onClickEditStaff(record)"
              style="margin-right: 16px"
              v-btn="'PC_USER_UPDATE'"
              class="table-text-color"
              >修改</a
            >
            <a
              @click="onChangeEditStaff(record)"
              style="margin-right: 16px"
              v-btn="'PC_USER_UPDATE'"
              class="table-text-color"
              v-if="record.identity === 1 && record.staffId === staffId"
              >移交</a
            >
            <a
              class="table-text-color"
              @click="openModal('delete', 'delete', record)"
              v-if="record.identity === 2"
              v-btn="'PC_USER_DELETE'"
              >删除</a
            >
          </div>
        </template> -->
      </a-table>
      <!-- bottom-btn -->
      <div
        :class="['bottom-btn', { 'checked-status': isChecked, 'sort-status': isSort }]"
        v-if="isChecked || isSort || (enterpriseRel === null && departmentId !== 0 && !isChecked)"
      >
        <template v-if="isChecked">
          <a-button class="btn" @click="cancel">取消</a-button>
          <a-button class="remove-btn btn" @click="openModal('delete', 'delete')" v-btn="'PC_USER_DELETE'"
            >批量删除</a-button
          >
          <a-button type="primary" @click="changeDepartment" v-btn="'PC_USER_UPDATE'">调整部门</a-button>
        </template>
        <template v-if="isSort">
          <span class="bottom-desc">
            <InfoCircleOutlined class="tip-icon" />提示：长按拖动按钮上下移动调整员工位置
          </span>
          <div>
            <a-button class="btn" @click="getCancel">取消</a-button>
            <a-button type="primary" @click="okSort">保存</a-button>
          </div>
        </template>
        <!-- <template v-if="enterpriseRel === null && departmentId !== 0 && !isChecked">
          <a-switch v-model:checked="checked" class="switch" @change="showDepartmentStaff" />
          <span class="show-department-desc">是否展示部门直属人员</span>
        </template> -->
      </div>
    </div>
    <modal
      :isAdd="isAdd"
      v-model:visible="visible"
      v-model:loading="loading"
      :type="actionType"
      :departmentList="copyDepartmentList"
      @modalSubmit="modalSubmit"
      :current="current"
      :departmentId="departmentId"
    />
    <treeExport
      @modalSubmit="modalSubmit"
      :reRender="reRender"
      v-model:visible="staffVisible"
      :types="actionType"
      v-model:loading="loading"
      v-model:upState="uploadState"
      :uploadCallbackData="uploadCallbackData"
    />
    <!-- 添加人员弹窗 -->

    <StaffModal
      v-model:visible="staffState.show"
      :info="staffState.info"
      :departmentInfo="departmentInfo"
      @changeInfo="changeInfo"
    />
    <!-- 调整部门弹窗 -->
    <RangeModal
      :visible="isShowRangeModal"
      title="调整部门"
      :type="11"
      :checkAll="false"
      :selectData="bothDepartment"
      @close="closeModal"
      @checkData="checkData"
    ></RangeModal>
    <!-- 移交弹窗 -->
    <a-modal
      centered
      title="选择人员"
      width="655px"
      class="global-range-modal"
      :visible="changeShow"
      @cancel="handledChangeCancel"
      @ok="handledChangeOk"
    >
      <GlobalSelector
        :visible="changeShow"
        :selectedList="turnOverSelectedList"
        :selectorInfo="selectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      >
      </GlobalSelector>
    </a-modal>

    <a-modal
      v-model:visible="modalShow"
      centered
      :width="502"
      :closable="false"
      class="delete-modal"
      @cancel="deleteModalCancel"
      @ok="deleteModalOk"
    >
      <div class="top-box">
        <ExclamationCircleFilled style="color: #FAAD14; font-size: 21px" />
        <div class="top-title">提示！</div>
      </div>
      <div class="center-box">
        <div class="headline-title">删除部门后，本部门关联的数据将不可见，请将数据归属转移后再进行删除</div>
        <div class="transmit-box">
          转交至：
          <div class="check-btn" @click="openModal('open', 'open')">
            <div class="check-box" v-if="checkDep.length">
              <div class="dep-title text-ellipsis">{{ checkDep[0].dataName }}</div>
              -
              <div class="company-name">
                <div class="company-name-box">
                  <div class="name">
                    {{ checkDep[0].ownershipName }}
                  </div>
                </div>
              </div>
            </div>
            <span v-else>请选择部门</span>
          </div>
          <DownOutlined class="icon-img" />
        </div>
      </div>
    </a-modal>

    <a-modal
      centered
      title="请选择部门"
      width="655px"
      class="global-range-modal"
      :visible="globalSelectorVisible"
      @cancel="globalSelectorCancel"
      @ok="globalSelectorOk"
    >
      <GlobalSelector
        :visible="globalSelectorVisible"
        :selectedList="checkDep"
        :selectorInfo="deptSelectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        ref="deptGlobalSelector"
      >
      </GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref,
  createVNode,
  onBeforeUnmount,
  watchEffect,
  computed,
  defineExpose
} from 'vue'
import {
  PlusOutlined,
  ExclamationOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  HolderOutlined,
  SearchOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
  DownOutlined
} from '@ant-design/icons-vue'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'
import { staffColumns, staffColumnsSort, childColumns, childSortColumns } from '../../columns'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { paginationParams } from '@/utils/constData'
import StaffModal from './staffModal/index.vue'
import RangeModal from '@/components/rangeModal'
import defaultImage from '@/assets/images/default-img.png'
import {
  addDepartment,
  deleteDepartment,
  editDepartment,
  getUserList,
  getDepartmentList,
  importDepartment,
  deleteSelectUser,
  reUserPass,
  importUser,
  personnelRanking,
  getAll,
  changeDepartmentApi,
  deleteUser,
  transferManage,
  getDepartmentOrStaff
} from '@/apis/businessManage'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import tree from './tree'
import modal from '@/views/businessManage/department/components/modal'
import treeExport from './modal'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import { Notification } from 'ant-design-vue'
import { cmsMessage } from '@/utils/utils'
import GlobalSelector from '@/components/GlobalSelector/index.vue'

export default {
  components: {
    PlusOutlined,
    tree,
    modal,
    treeExport,
    StaffModal,
    InfoCircleOutlined,
    // MenuOutlined,
    HolderOutlined,
    RangeModal,
    GlobalSelector,
    ExclamationCircleFilled,
    DownOutlined
  },
  setup(props, { expose }) {
    const globalSelector = ref(null)
    const selectorInfo = ref({
      placeholder: '请输入员工姓名或部门',
      limitCount: 1,
      abelCheckAll: true,
      ableSelectDep: true,
      ableChangeData: true
    })
    const deptSelectorInfo = reactive({
      placeholder: '请输入部门名称查询111',
      limitCount: 1,
      ableSelectDep: true,
      limitWarningInfo: '当前已添加至部门上限请删除已选部门后重试'
    })
    const selectorType = ref(1)
    const sourceObj = ref({}) //源对象
    const targetObj = ref({}) //目标对象
    const isShowOperation = ref(true) //树结构操作icon显示条件
    const isSort = ref(false) //是否进入排序状态
    const total = ref(0) //列表数据总数
    const sortInfo = ref([]) //整理后发给后台的数组
    const tableRef = ref()
    const store = useStore()
    const state = reactive({
      selectedRowKeys: [], //选中项的 key 数组
      selectedRows: [], //选中行信息
      disabled: false, // 是否禁止选择
      treeData: [],
      staffColumns,
      staffColumnsSort,
      searchVal: undefined, //表格搜索框
      tableLoading: true, //表格加载
      dataSource: [], //表格列表
      visible: false, //树结构显示框
      isAdd: false, //添加部门
      loading: false,
      actionType: 'add',
      userInfo: undefined,
      copyDepartmentList: [], //上级部门列表
      searchValue: undefined, //树结构搜索框
      departmentTitle: '',
      departmentId: 0,
      enterpriseRel: undefined, //左侧树选中部门
      enterpriseId: undefined,
      canCreateStaff: undefined, //是否可以添加人员
      current: {}, //左侧修改部门数据
      staffVisible: false, //部门批量导入、重置密码
      reRender: {}, //重置密码数据
      totalUser: [], //列表总数据
      start: 0, //数据截取开始位置
      page: 1, //页码
      isShowRangeModal: false, //是否显示调整部门弹窗
      bothDepartment: [],
      departmentInfo: [], //选中的组织信息
      isShowAddBtn: true, //是否显示添加人员按钮
      columns: staffColumns, //table列值
      currentRow: undefined, //拖拽当前行
      // personList: [], // 人员列表
      changeShow: false, // 选择人员弹窗
      clickPerson: {}, // 选中人员
      staffId: '', // 当前登录人员id
      // allPerson: [], //
      modalShow: false, //删除弹窗
      globalSelectorVisible: false, // 选择部门的弹窗
      checkDep: [], // 选择的部门
      deleteLoading: false, // 删除loading
      checkDepId: '',
      uploadState: 0, //批量导入人员上传状态
      uploadCallbackData: {}, //批量导入接口数据
      turnOverSelectedList: [] //移交的人员列表
    })

    const leftBtnRef = ref(null)
    const isChecked = ref(false) //有无勾选
    const staffIdList = ref([])
    const checked = ref(true)
    const isAdmin = ref(1)

    // 人员弹窗数据
    const staffState = reactive({
      show: false,
      info: null
    })

    // 点击添加人员
    const onClickAddStaff = () => {
      staffState.show = true
      staffState.info = null
      clearSelected()
    }

    // 点击修改人员
    const onClickEditStaff = current => {
      clearSelected()
      console.log('修改人员', current)
      staffState.show = true
      staffState.info = current
    }

    // 获取全部成员
    // const getAllPerson = async () => {
    //   const res = await getDepartmentOrStaff({ selectorType: 3 })
    //   if (res.success) {
    //     if (res.data.staffList && res.data.staffList.length !== 0) {
    //       state.personList = res.data.staffList.map(item => {
    //         item.islower = false
    //         item.dataName = item.staffName
    //         item.dataType = 1
    //         item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
    //         return item
    //       })
    //     }
    //     state.personList = state.personList.filter(it => it.identity !== 1)
    //     state.allPerson = state.personList
    //   }
    // }

    // 点击移交
    const onChangeEditStaff = async value => {
      // console.log('不带超管的', state.personList)
      selectorType.value = 1
      isAdmin.value = 2
      await getScopeList()
      state.turnOverSelectedList = []
      state.changeShow = true
    }

    // 添加、编辑人员时，保存部门信息
    onBeforeRouteLeave((to, from) => {
      const staffRoutes = ['/businessManage/staff/add', '/businessManage/staff/edit']
      if (staffRoutes.includes(to.path)) {
        let lastDepartmentInfo = { ...store.getters.lastDepartmentInfo }
        lastDepartmentInfo.departmentId = state.departmentId
        lastDepartmentInfo.departmentTitle = state.departmentTitle
        store.commit('globalData/SET_LAST_DEPARTMENT_INFO', lastDepartmentInfo)
      }
    })

    // 读取缓存的部门信息
    if (store.getters.lastDepartmentInfo.departmentId >= 0) {
      let lastDepartmentInfo = { ...store.getters.lastDepartmentInfo }
      state.departmentId = lastDepartmentInfo.departmentId
      state.departmentTitle = lastDepartmentInfo.departmentTitle
      store.commit('globalData/ADD_LAST_DEPARTMENT_INFO')
    }

    //获取左侧部门树
    const getDepartment = async () => {
      const res = await getDepartmentList()
      if (res.success) {
        state.treeData = res.data
        state.copyDepartmentList = res.data
        if (state.departmentId == 0) {
          state.departmentTitle = res.data[0].departmentName
        } else {
          const list = dataList.value.map(item => item.departmentId)
          if (state.departmentId && !list.includes(state.departmentId)) {
            state.departmentId == 0
            state.departmentTitle = res.data[0].departmentName
            getList()
          }
        }
      }
    }

    const dataList = computed(() => {
      const generate = tempArr => {
        return tempArr.reduce((result, item) => {
          const chilren = generate(item.childrenDepartmentList || [])
          return [...result, ...chilren, item]
        }, [])
      }
      return generate(state.copyDepartmentList)
    })
    //右侧表单列表
    const getList = async () => {
      document.getElementsByClassName('ant-table-body')[0].scrollTop = 0
      tableRef.value.$el.setAttribute('load-more-disabled', 'false')
      state.page = 1
      state.start = 0
      state.tableLoading = true
      const params = {
        search: state.searchVal,
        departmentId: state.departmentId,
        enterpriseRel: state.departmentId == 0 ? null : state.enterpriseRel,
        // isDirectStaff: checked.value ? 1 : 0,
        isDirectStaff: 1,
        enterpriseId: state.enterpriseId
      }
      const res = await getUserList(params)
      state.totalUser = res.data
      console.log('state.totalUser', state.totalUser)
      state.dataSource = state.totalUser.slice(0, 10)
      state.tableLoading = false
      state.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId
      // getAllPerson()
    }
    //删除人员
    const removeStaff = async it => {
      console.log('员工列表', staffIdList.value[0], it)
      // return
      let params = {
        staffIdList: staffIdList.value
      }
      const res = await (it ? deleteUser({ id: staffIdList.value[0] }) : deleteSelectUser(params))
      if (res.success) {
        cmsNotice('success', '删除成功')
        await getList()
      }
      clearSelected()
    }
    //树菜单的添加部门和批量导入
    const openModal = (type, sel, e) => {
      if (sel !== 'delete') {
        clearSelected()
      }
      console.log('e', type, e)
      //左侧树结构的底部添加部门按钮
      if (sel === 'treeAdd') {
        if (state.disabled) return
        state.actionType = type
        state.visible = true
        state.isAdd = true
      }
      if (sel === 'delete') {
        if (e) {
          staffIdList.value = []
          staffIdList.value.push(e.staffId)
        }
        Modal.confirm({
          title: '是否删除！',
          width: '25%',
          content: '删除后无法恢复，是否删除？',
          centered: true,
          confirmLoading: state.loading,
          icon: createVNode(ExclamationOutlined),
          onOk: () => {
            removeStaff(e)
          }
        })
      }
      if (sel === 'treeExport') {
        if (state.disabled) return
        state.actionType = type
        state.visible = true
        state.isAdd = false
      }
      if (sel === 'pass') {
        state.actionType = type
        state.staffVisible = true
        state.reRender = e
      }
      if (sel === 'import') {
        state.actionType = type
        state.staffVisible = true
      }
      if (type == 'open') {
        state.globalSelectorVisible = true
        selectorType.value = 2
        getScopeList()
      }
    }

    const renderList = ref()
    //去下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //搜索
    const getSearchInfo = async e => {
      let data = {
        searchName: e
      }
      getScopeList(data)
    }
    // 处理左侧数据
    const handelData = res => {
      renderList.value = formateListData(res)
      console.log('处理后的renderList的值', renderList.value)
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: selectorType.value, //1部门人员2部门3员工
        isAdmin: isAdmin.value //移交时不展示超管
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        // console.log('全部数据', res)
        handelData(res)
      }
    }

    //左侧树结构添加部门弹框逻辑
    const modalSubmit = async value => {
      let isSuccess = false

      if (state.actionType === 'export') {
        const res = await importDepartment({ file: value })
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '上传成功')
        }
      } else if (state.actionType === 'add' || state.actionType === 'addChild') {
        const params = {
          departmentName: value.department,
          parentId: value.nextDepart || 0,
          type: value.type,
          leaderId: value.leaderId
        }
        const res = await addDepartment(params)
        if (res.success) {
          cmsNotice('success', '添加成功')
          isSuccess = true
          state.loading = false
          if (isSuccess) {
            await getDepartment()
            await getList()
            state.visible = false
            state.staffVisible = false
          }
          return
        }
      } else if (state.actionType === 'edit') {
        const params = {
          departmentName: value.department,
          parentId: value.nextDepart || 0,
          type: value.type,
          leaderId: value.leaderId,
          id: state.current.departmentId
        }
        const res = await editDepartment(params)
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '修改成功')
        }
      } else if (state.actionType === 'pass') {
        const res = await reUserPass({ userId: value.userId, newPassword: value.pass })
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '修改成功')
        }
      } else if (state.actionType === 'import') {
        state.uploadState = 1
        const timer = setTimeout(() => {
          isSuccess = false
          state.uploadState = 3
          state.uploadCallbackData = { errMsg: '导入失败，请检查网络设置' }
        }, 10 * 1000)
        const res = await importUser({ file: value })
        clearTimeout(timer)
        if (res.success) {
          if (res.data.success) {
            isSuccess = true
            state.uploadState = 2
            state.uploadCallbackData = res.data
            // cmsNotice('success', '上传成功')
          } else {
            isSuccess = false
            state.uploadState = 3
            state.uploadCallbackData = res.data
            // cmsNotice('error', '上传失败，请联系管理员')
          }
        }
      }
      state.loading = false
      state.departmentId = 0
      if (isSuccess) {
        await getDepartment()
        await getList()
        state.visible = false
        if (state.actionType != 'import') state.staffVisible = false
      }
    }
    //树结构修改按钮 edit修改 delete 删除
    const handledEdit = async (type, e) => {
      clearSelected()
      console.log('e11', e)
      if (type === 'addChild') {
        state.isAdd = true
        state.visible = true
        state.current = e.dataRef
        state.actionType = type
      }
      if (type === 'edit') {
        state.isAdd = true
        state.visible = true
        state.current = e.dataRef
        state.actionType = type
      }
      if (type === 'delete') {
        // Modal.confirm({
        //   title: '是否删除！',
        //   width: '25%',
        //   content: '删除后无法恢复，是否删除？',
        //   centered: true,
        //   confirmLoading: state.loading,
        //   icon: createVNode(ExclamationOutlined),
        //   onOk: async () => {
        //     const res = await deleteDepartment({ id: e.dataRef.departmentId })
        //     if (res.success) {
        //       cmsNotice('success', '删除成功')
        //       state.departmentId = 0
        //       await getDepartment()
        //       await getList()
        //     }
        //   }
        // })
        state.checkDep = []
        state.modalShow = true
        state.checkDepId = e.departmentId
      }
    }

    // 删除弹窗取消
    const deleteModalCancel = () => {
      state.modalShow = false
    }
    // 删除弹窗确认
    const deleteModalOk = async () => {
      if (state.deleteLoading) return
      state.deleteLoading = true
      if (!state.checkDep.length) {
        Notification['warning']({
          message: '提示',
          description: '请选择转交部门'
        })
        state.deleteLoading = false
        return
      }
      console.log('当前权限', state.checkDep[0])
      const res = await deleteDepartment({ id: state.checkDepId, toDeptId: state.checkDep[0].dataId })
      if (res.success) {
        cmsNotice('success', '删除成功')
        state.departmentId = 0
        await getDepartment()
        await getList()
        state.deleteLoading = false
      }
      state.deleteLoading = false
      deleteModalCancel()
    }

    // 部门弹窗实例
    const deptGlobalSelector = ref(null)
    //弹窗确实事件
    const globalSelectorOk = () => {
      let arr = deptGlobalSelector.value.okEvent()
      console.log('arr', arr)
      state.checkDep = [...arr]
      // state.selectedList = [...arr]
      globalSelectorCancel()
    }
    // 弹窗取消事件
    const globalSelectorCancel = () => {
      state.globalSelectorVisible = false
      deptGlobalSelector.value.cancelEvent()
    }

    //树结构点击选中的部门
    const onSelect = e => {
      clearSelected()
      console.log('树结构点击选中的部门', e)
      if (e.departmentId !== 0) {
        state.departmentInfo = [
          {
            departmentId: e.departmentId,
            departmentName: e.departmentName
          }
        ]
      } else {
        state.departmentInfo = []
      }
      state.departmentId = e.departmentId
      state.enterpriseRel = e.enterpriseRel
      state.enterpriseId = e.enterpriseId
      state.canCreateStaff = e.canCreateStaff
      console.log('state.departmentId', state.departmentId)
      state.departmentTitle = e.departmentName
      state.isShowAddBtn = state.canCreateStaff === null || state.canCreateStaff !== false
      getList()
    }
    //表单搜索框按钮
    const handledSearch = () => {
      cancel()
    }
    const onSearch = e => {
      state.searchValue = e
    }
    const handledSearchValue = e => {
      clearSelected()
      state.searchValue = e.target.value.length > 0 ? e.target.value : undefined
      console.log('----444-----', state.searchValue)
    }

    // 排序按钮
    const getSort = async () => {
      // console.log('ssss', state);
      // return
      tableRef.value.$el.setAttribute('load-more-disabled', 'true')
      console.log('load-more-disabled', tableRef.value.$el.getAttribute('load-more-disabled'))
      sortInfo.value = []
      clearSelected()
      isShowOperation.value = false
      isSort.value = true
      state.searchVal = undefined
      const params = {
        search: state.searchVal,
        departmentId: state.departmentId,
        enterpriseRel: state.departmentId == 0 ? null : state.enterpriseRel,
        isDirectStaff: checked.value ? 1 : 0,
        enterpriseId: state.enterpriseId
      }
      getUserList(params)
        .then(res => {
          state.dataSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      document.getElementsByClassName('ant-table-body')[0].scrollTop = 0
      state.disabled = true //禁用左侧树结构
    }

    // 取消排序
    const getCancel = () => {
      state.disabled = false
      state.searchVal = undefined
      getList()
      isSort.value = false
      isShowOperation.value = true
    }

    // 确认排序
    const okSort = async () => {
      console.log('部门数据', state.departmentId)
      // return
      Modal.confirm({
        title: '是否保存！',
        width: '25%',
        content: '请确认是否保存修改后的排序设置',
        centered: true,
        confirmLoading: state.loading,
        icon: createVNode(ExclamationOutlined),
        onOk: () => {
          state.loading = true
          let enterpriseId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId
          if (state.enterpriseId && !state.enterpriseRel) {
            //集团
            enterpriseId = state.enterpriseId
          } else if (state.enterpriseRel) {
            //子公司
            enterpriseId = state.enterpriseRel
          }
          personnelRanking(enterpriseId, { staffSortIndexList: sortInfo.value, departmentId: state.departmentId })
            .then(res => {
              console.log(res)
              state.disabled = false
              state.searchVal = undefined
              state.loading = false
              isSort.value = false
              isShowOperation.value = true
              getList()
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    }

    //选择框
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      isChecked.value = selectedRowKeys.length > 0
      staffIdList.value = selectedRowKeys
      console.log('c', staffIdList.value)
      state.selectedRowKeys = selectedRowKeys
      console.log('选中行的信息', selectedRows)
      state.selectedRows = selectedRows
    }
    //列表全选
    const onSelectAll = (selected, selectedRows, changeRows) => {
      console.log('列表全选', selected, selectedRows, changeRows)
      state.totalUser.forEach(item => {
        state.selectedRowKeys.push(item.staffId)
      })
      //取消全选
      if (!selected) {
        clearSelected()
      }
    }
    //取消选择
    const cancel = () => {
      clearSelected()
      getList()
    }

    // 拖拽行
    const customRow = (record, index) => {
      return {
        style: {
          cursor: 'grab'
        },
        // 鼠标移入
        onMouseenter: event => {
          // 兼容IE
          var ev = event || window.event
          ev.target.draggable = true
        },
        // 开始拖拽
        onDragstart: event => {
          // 兼容IE
          var ev = event || window.event
          // 阻止冒泡
          ev.stopPropagation()
          // 得到源目标数据
          sourceObj.value = record

          state.currentRow = index
        },
        // 拖动元素经过的元素
        onDragover: event => {
          // 兼容 IE
          var ev = event || window.event
          // 阻止默认行为
          ev.preventDefault()

          console.log('拖拽当前行的索引', state.currentRow, index)
          if (index >= state.currentRow) {
            //向下拖拽
            document.getElementsByClassName('ant-table-tbody').forEach(parent => {
              parent.getElementsByTagName('tr').forEach((item, i) => {
                if (i === index) {
                  item.style.borderBottom = '2px solid #1677ff'
                } else {
                  item.removeAttribute('style')
                }
              })
            })
          } else {
            document.getElementsByClassName('ant-table-tbody').forEach(parent => {
              parent.getElementsByTagName('tr').forEach((item, i) => {
                if (i === index) {
                  item.style.borderTop = '2px solid #1677ff'
                } else {
                  item.removeAttribute('style')
                }
              })
            })
          }
        },
        // 鼠标松开
        onDrop: event => {
          // 兼容IE
          var ev = event || window.event
          // 阻止冒泡
          ev.stopPropagation()
          // 得到目标数据
          targetObj.value = record
          console.log('源数据', sourceObj.value)
          console.log('目标数据', targetObj.value)

          const newArr = state.dataSource
          console.log('newArr的值', newArr)

          const source = newArr.findIndex(item => item.staffId == sourceObj.value.staffId)
          console.log('source的值', source)

          const target = newArr.findIndex(item => item.staffId == targetObj.value.staffId)
          console.log('target的值', target)

          newArr.splice(source, 1)
          newArr.splice(target, 0, sourceObj.value)
          sortInfo.value = []
          newArr.forEach((item, index) => {
            sortInfo.value.push({
              staffId: item.staffId,
              sortIndex: index
            })
          })
          document.getElementsByClassName('ant-table-tbody').forEach(parent => {
            parent.getElementsByTagName('tr').forEach(child => {
              child.removeAttribute('style')
            })
          })
        },
        //鼠标移出
        onMouseleave: event => {
          // 兼容IE
          var ev = event || window.event
          ev.target.draggable = false
          document.getElementsByClassName('ant-table-tbody').forEach(parent => {
            parent.getElementsByTagName('tr').forEach(child => {
              child.removeAttribute('style')
            })
          })
        }
      }
    }
    //加载更多
    const tableLoad = () => {
      console.log('加载更多', state.page)
      if (state.page >= Math.ceil(state.totalUser.length / 10)) {
        tableRef.value.$el.setAttribute('load-more-disabled', 'true')
        console.log('table属性值', tableRef.value.$el.getAttribute('load-more-disabled'))
        return
      }
      state.page += 1
      console.log('当前页', state.page)
      state.start += 10
      state.dataSource = [...state.dataSource, ...state.totalUser.slice(state.start, state.start + 10)]
      console.log('新数据长度', state.dataSource.length, state.totalUser.length)
    }
    //是否展示部门直属人员
    const showDepartmentStaff = () => {
      getList()
    }
    //遍历相同部门方法
    const commonDepartment = () => {
      let newArr = []
      state.selectedRows[0].departmentRedisVOList.forEach((item, index) => {
        newArr[index] = []
        state.selectedRows.forEach(ele => {
          newArr[index].push(ele.departmentRedisVOList.some(value => value.departmentId === item.departmentId))
        })
      })

      let finalres = []
      newArr.forEach((item, index) => {
        finalres.push(item.some(value => value === false))
      })
      if (finalres.some(value => value === true)) return //存在不相同的部门

      //只有选中的人员的部门完全相同且不是企业名时才回显
      state.selectedRows[0].departmentRedisVOList.forEach(item => {
        if (item.departmentId === 0) return
        state.bothDepartment.push({
          dataName: item.departmentName,
          dataType: 2,
          dataId: item.departmentId
        })
      })
    }
    //调整部门
    const changeDepartment = () => {
      //显示调整部门弹窗
      state.isShowRangeModal = true
      //获取相同部门回显

      let newArr = []
      let firthLength = state.selectedRows[0].departmentRedisVOList.length
      state.selectedRows.forEach(item => {
        newArr.push(item.departmentRedisVOList.length === firthLength)
      })
      let res = newArr.some(value => value === false)
      if (res) return
      commonDepartment()
    }
    //关闭调整部门弹窗
    const closeModal = () => {
      state.isShowRangeModal = false
      state.bothDepartment = []
    }
    //调整部门弹窗确定提交
    const checkData = e => {
      console.log('选中数据处理', e)
      let deptIdList = []
      e.forEach(item => {
        deptIdList.push(item.dataId)
      })
      // 调整部门接口
      changeDepartmentApi({ staffIdList: staffIdList.value, deptIdList })
        .then(res => {
          Notification.destroy()
          if (res.success) {
            cmsMessage('success', '部门调整成功')
            getList()
          } else {
            cmsMessage('error', '部门调整失败')
          }
          clearSelected()
        })
        .catch(error => {
          console.log(error)
          clearSelected()
        })
    }
    // 搜索列表
    // const searchChange = e => {
    //   console.log('11', e)
    //   const list = state.allPerson.filter(item => item.identity !== 1)
    //   if (e === null) {
    //     state.personList = list
    //     return
    //   }
    //   state.personList = []
    //   list.forEach(element => {
    //     if (element.staffName.includes(e)) {
    //       state.personList.push(element)
    //     }
    //   })
    // }
    // 确认
    const handledChangeOk = () => {
      let arr = [...globalSelector.value.okEvent()]
      // if (!state.clickPerson.dataId) {
      if (arr.length === 0) {
        cmsMessage('error', '请选择人员')
        return
      }
      transformData(arr).then(res => {
        state.turnOverSelectedList = res
        state.clickPerson = res[0]
        console.log('state.clickPerson的值', state.clickPerson)
        // 二次弹窗
        Modal.confirm({
          title: `确认将超级管理员移交给${state.clickPerson.dataName}吗？`,
          content: '确定后，超级管理员身份及全部权限将发生转移，且当前账户将会自动登出，请谨慎操作',
          centered: true,
          width: '490px',
          confirmLoading: state.loading,
          icon: createVNode(ExclamationOutlined),
          onOk: async () => {
            const res = await transferManage({ transferStaffId: state.clickPerson.dataId })
            console.log('888888888', res)
            if (res.success) {
              handledChangeCancel()
              // 重新获取列表
              getList()
            }
          }
        })
      })
    }
    // 取消
    const handledChangeCancel = () => {
      state.changeShow = false
      // state.personList = state.allPerson
      state.clickPerson = {}
      globalSelector.value.cancelEvent()
      isAdmin.value = 1
    }

    const changeInfo = () => {
      // staffState.show = false
      getList()
    }
    watchEffect(() => {
      //排序状态下，集团及其部门显示操作栏，子公司不显示操作栏
      // state.columns = isSort.value
      //   ? !state.isShowAddBtn
      //     ? childSortColumns
      //     : staffColumnsSort
      //   : !state.isShowAddBtn
      //   ? childColumns
      //   : staffColumns
      state.columns = isSort.value ? childSortColumns : !state.isShowAddBtn ? childColumns : staffColumns
    })
    //清除勾选
    const clearSelected = () => {
      state.selectedRowKeys = []
      state.departmentInfo = []

      isChecked.value = false
    }
    // 切换数据
    const changeData = ({ isOrganizationData, searchValue }) => {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        selectorType.value = 1
      } else {
        //人员数据
        selectorType.value = 3
      }
      getSearchInfo(searchValue)
    }
    onMounted(async () => {
      getDepartment()
      getList()
    })
    expose({ clearSelected })
    return {
      getDepartment,
      ...toRefs(state),
      tableRef,
      handledEdit,
      modalSubmit,
      openModal,
      onSelect,
      handledSearch,
      onSearch,
      handledSearchValue,
      getSort,
      okSort,
      getCancel,
      customRow,
      leftBtnRef,
      total,
      isSort,
      isShowOperation,
      staffState,
      onClickAddStaff,
      onClickEditStaff,
      onSelectChange,
      isChecked,
      removeStaff,
      staffIdList,
      cancel,
      sourceObj,
      targetObj,
      checked,
      tableLoad,
      defaultImage,
      showDepartmentStaff,
      changeDepartment,
      closeModal,
      onSelectAll,
      checkData,
      changeInfo,
      onChangeEditStaff,
      // searchChange,
      handledChangeOk,
      handledChangeCancel,
      clearSelected,
      // getAllPerson,
      selectorInfo,
      globalSelector,
      toNext,
      getSearchInfo,
      deptSelectorInfo,
      globalSelectorOk,
      deptGlobalSelector,
      globalSelectorCancel,
      renderList,
      deleteModalOk,
      deleteModalCancel,
      changeData,
      selectorType
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  border-radius: 4px;
  display: flex;
  padding: 0;
  flex-direction: row;

  .content-left {
    background-color: #ffffff;
    width: 244px;
    padding: 24px 24px 16px;
    flex: none;
    position: relative;

    .left-input {
      margin-top: 24px;
    }

    .tree-view {
      margin-top: 16px;
      margin-bottom: 40px;
      max-height: 600px;
      height: 550px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      .tree-view-item {
        overflow-y: hidden;
        overflow-x: auto;
      }
    }

    .left-btn {
      display: flex;
      justify-content: center;

      .left-btn-item {
        font-size: 14px;
        font-weight: 400;
        color: #323233;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        width: 94px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .left-btn-cursor {
        opacity: 0.5;
        cursor: no-drop;
      }
    }

    .addOrgBtn {
      position: absolute;
      bottom: 32px;
      width: 100%;

      :deep(.ant-btn-dashed) {
        width: 196px;
      }
    }

    .fiexd {
      position: fixed;
      bottom: 0;
    }
  }

  .content-right {
    margin-left: 1px;
    color: black;
    width: calc(100% - 245px);
    padding: 24px;
    transform: scale(1);
    background: #fff;

    .content-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
      margin-bottom: 24px;

      .search-view {
        display: flex;

        .form-input {
          width: 272px;
        }

        .form-btn {
          margin-left: 16px;
        }
      }
    }

    .tab-btn {
      display: flex;

      .table-text-color {
        color: #1890ff;
      }
    }

    .name-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .span-container {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
        width: auto;
      }

      img {
        width: 52px;
        height: 28px;
        margin-left: 4px;
      }
    }

    .span-department {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 120px;
    }

    .bottom-btn {
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 0 24px;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.03);
      background: #fff;
      z-index: 99;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      line-height: 56px;

      .btn {
        margin-right: 8px;
      }

      .remove-btn {
        border: 1px solid #c3161c;
        color: #c3161c;
      }

      .bottom-desc {
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        .tip-icon {
          margin-right: 8px;
        }
      }

      .switch {
        margin-right: 8px;
      }

      .show-department-desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .checked-status {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .sort-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
<style lang="less" scoped>
.sort-input {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 400;
  color: #595959;

  &:focus {
    outline: none;
    border: none;
    border: 1px solid #c3161c;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
}

.text-box {
  max-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 0px 16px;
  overflow-wrap: break-word;
  height: 55px;
  line-height: 55px;
}

.text-ellipsis-10 {
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.person {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 16px;
}
.person-list {
  height: 308px;
  // overflow: hidden;
  overflow-y: auto;
  .person-item {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .person-item-left {
      display: flex;
    }
    .per-avatar {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .per-name {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

<style scoped lang="less">
.change-modal .ant-modal-header {
  border-bottom: 0 !important;
}
.change-modal .ant-modal-body {
  padding-top: 0;
}

.top-box {
  display: flex;

  .top-title {
    margin-left: 17px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.center-box {
  margin: 12px 0 0 40px;

  .headline-title {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    line-height: 22px;
  }

  .transmit-box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    font-size: 14px;
    position: relative;

    .check-btn {
      width: 334px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      padding: 5px 12px;
      cursor: pointer;

      .check-box {
        display: flex;
        width: 289px;
      }
      .dep-title {
        max-width: 168px;
      }

      .company-name {
        flex: 1;
        overflow: hidden;
      }
      .company-name-box {
        display: flex;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .icon-img {
      font-size: 12px;
      position: absolute;
      right: 20px;
      color: rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
</style>
